<template>
  <router-view/>
</template>

<style>
 @import url('https://fonts.googleapis.com/css2?family=Monoton&family=Poppins:ital,wght@0,400;1,300;1,400&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;600;900&display=swap');
*{
  font-family: 'Montserrat', sans-serif;
}
html,body{
  scroll-behavior: smooth;
}
.sport-title{
    font-family: 'Monoton', cursive;
    font-weight:normal;
    }
</style>
