<template>
    <Nav/>
  <Banner/>
  <section class="py-3 bg-light">
    <div class="container">
        <div class="row py-5">
            <div class="col-10 offset-1">
                <div class="lh-sm py-3">
                    <h3 class="fw-bold text-start text-uppercase">
                        Be part of the family.
                    </h3>
                    <span class="fw-bold small text-muted">

                    </span>
                </div>
                <div class="py-2">
                    <form class="row g-3">
                        <div class="col-md-6">
                            <label for="inputEmail4" class="form-label">Email</label>
                            <input type="email" class="form-control rounded-0" id="inputEmail4">
                            <div id="emailHelp" class="form-text">We'll never share your email with anyone else.</div>
                        </div>
                        <div class="col-md-6">
                            <label for="inputPassword4" class="form-label">Password</label>
                            <input type="password" class="form-control rounded-0" id="inputPassword4">
                        </div>
                        <div class="col-12">
                            <label for="inputAddress" class="form-label">Address</label>
                            <input type="text" class="form-control rounded-0 " id="inputAddress" placeholder="1234 Main St">
                        </div>
                        <div class="col-12">
                            <label for="inputAddress2" class="form-label">Address 2</label>
                            <input type="text" class="form-control rounded-0" id="inputAddress2" placeholder="Apartment, studio, or floor">
                        </div>
                        <div class="col-md-6">
                            <label for="inputCity" class="form-label">City</label>
                            <input type="text" class="form-control rounded-0" id="inputCity">
                        </div>
                        <div class="col-md-4">
                            <label for="inputState" class="form-label">State</label>
                            <select id="inputState" class="form-select rounded-0">
                            <option selected>Choose...</option>
                            <option>...</option>
                            </select>
                        </div>
                        <div class="col-md-2">
                            <label for="inputZip" class="form-label">Zip</label>
                            <input type="text" class="form-control rounded-0" id="inputZip">
                        </div>
                        <div class="col-12">
                            <div class="form-check">
                            <input class="form-check-input rounded-0" type="checkbox" id="gridCheck">
                            <label class="form-check-label" for="gridCheck">
                                Check me out
                            </label>
                            </div>
                        </div>
                        <div class="col-12">
                            <button type="submit" class="btn btn-primary rounded-0">Sign in</button>
                        </div>
                        </form>
                </div>
            </div>
        </div>
    </div>
  </section>
  <Footer/>
</template>

<script>
import Nav from '@/components/Layout/Nav.vue'
import Banner from '@/components/Layout/Banner.vue'
import Footer from '@/components/Layout/Footer.vue'
export default {
    name:'EnrollView',
    components:{
        Nav,
        Banner,
        Footer
    }
}
</script>

<style>

</style>