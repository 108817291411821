<template>
  <Nav/>
    <Banner/>
    <section class="py-5">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="py-3">
                        <h3 class="fw-bold sport-title text-center">SQUAD LIST</h3>
                    </div>
                </div>
                <div class="d-flex justify-content-start align-items-start">
                         <div class="card" style="height:250px; width:250px">
                            <img src="../assets/images/banner/spx-5.jpg" alt="" class="card-img h-100 object-fit-cover">
                         </div>
                         <div class="lh-sm ps-3 align-self-center">
                            <h3 class="fw-bold">
                                Head Coach
                            </h3>
                            <p class="mb-0"> Coach. Komolafe Adigun .O</p>
                            <p>Nigerian </p>
                         </div>
                </div>
           
            </div> 
        </div>
    </section>
    <section>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="text-center mb-3 ">
                    <ul class="nav nav-pills d-flex justify-content-center py-2 mb-3" id="pills-tab" role="tablist">
                        <li class="nav-item" role="presentation">
                            <button class="nav-link active bg-light rounded-0 border border-0 text-dark fw-bold" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Little Stars</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link text-muted bg-light fw-bold" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Shining Stars</button>
                        </li>
                       
                    </ul>   
                    <div class="tab-content border border-0 rounded-3 py-3 text-center" id="pills-tabContent">
                        <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabindex="0">
                           <div class="row">
                                 <!--player 1-->
                                    <div class="col-lg-2 col-md-2 col-sm-6">
                                        <div class="card rounded-0 border border-0 shadow-sm" style="height:250px">
                                            <div style="max-height:65%">
                                                <img src="../assets/images/pages/stad.jpg" alt="" class="card-img-top h-100 object-fit-cover ">
                                            </div>
                                            <div class="card-body text-center">
                                                <div class="card-title h6 fw-bold text-center">
                                                    Joseph Aribo
                                                </div>
                                                <span class="d-block small tex-muted fw-semibold lh-sm">
                                                        6years
                                                </span>
                                                <span class="d-block small tex-muted fw-semibold lh-sm">
                                                        Striker
                                                </span>
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <!--player-->
                                     <!--player 1-->
                                    <div class="col-lg-2 col-md-2 col-sm-6">
                                        <div class="card rounded-0 border border-0 shadow-sm" style="height:250px">
                                            <div style="max-height:65%">
                                                <img src="../assets/images/pages/stad.jpg" alt="" class="card-img-top h-100 object-fit-cover ">
                                            </div>
                                            <div class="card-body text-center">
                                                <div class="card-title h6 fw-bold text-center">
                                                    Joseph Aribo
                                                </div>
                                                <span class="d-block small tex-muted fw-semibold lh-sm">
                                                        6years
                                                </span>
                                                <span class="d-block small tex-muted fw-semibold lh-sm">
                                                        Striker
                                                </span>
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <!--player-->
                                     <!--player 1-->
                                    <div class="col-lg-2 col-md-2 col-sm-6">
                                        <div class="card rounded-0 border border-0 shadow-sm" style="height:250px">
                                            <div style="max-height:65%">
                                                <img src="../assets/images/pages/stad.jpg" alt="" class="card-img-top h-100 object-fit-cover ">
                                            </div>
                                            <div class="card-body text-center">
                                                <div class="card-title h6 fw-bold text-center">
                                                    Joseph Aribo
                                                </div>
                                                <span class="d-block small tex-muted fw-semibold lh-sm">
                                                        6years
                                                </span>
                                                <span class="d-block small tex-muted fw-semibold lh-sm">
                                                        Striker
                                                </span>
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <!--player-->
                                     <!--player 1-->
                                    <div class="col-lg-2 col-md-2 col-sm-6 ">
                                        <div class="card rounded-0 border border-0 shadow-sm" style="height:250px">
                                            <div style="max-height:65%">
                                                <img src="../assets/images/pages/stad.jpg" alt="" class="card-img-top h-100 object-fit-cover ">
                                            </div>
                                            <div class="card-body text-center">
                                                <div class="card-title h6 fw-bold text-center">
                                                    Joseph Aribo
                                                </div>
                                                <span class="d-block small tex-muted fw-semibold lh-sm">
                                                        6years
                                                </span>
                                                <span class="d-block small tex-muted fw-semibold lh-sm">
                                                        Striker
                                                </span>
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <!--player-->
                                     <!--player 1-->
                                    <div class="col-lg-2 col-md-2 col-sm-6 col-xs-6">
                                        <div class="card rounded-0 border border-0 shadow-sm" style="height:250px">
                                            <div style="max-height:65%">
                                                <img src="../assets/images/pages/stad.jpg" alt="" class="card-img-top h-100 object-fit-cover ">
                                            </div>
                                            <div class="card-body text-center">
                                                <div class="card-title h6 fw-bold text-center">
                                                    Joseph Aribo
                                                </div>
                                                <span class="d-block small tex-muted fw-semibold lh-sm">
                                                        6years
                                                </span>
                                                <span class="d-block small tex-muted fw-semibold lh-sm">
                                                        Striker
                                                </span>
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <!--player-->
                                     <!--player 1-->
                            <div class="col-lg-2 col-md-2 col-sm-6 col-xs-6">
                                <div class="card rounded-0 border border-0 shadow-sm" style="height:250px">
                                    <div style="max-height:65%">
                                        <img src="../assets/images/pages/stad.jpg" alt="" class="card-img-top h-100 object-fit-cover ">
                                    </div>
                                    <div class="card-body text-center">
                                        <div class="card-title h6 fw-bold text-center">
                                            Joseph Aribo
                                        </div>
                                        <span class="d-block small tex-muted fw-semibold lh-sm">
                                                6years
                                        </span>
                                        <span class="d-block small tex-muted fw-semibold lh-sm">
                                                Striker
                                        </span>
                                        
                                    </div>
                                </div>
                            </div>
                            <!--player-->
                           </div>
                            
                        </div>
                        <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab" tabindex="0">...</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </section>
  <Footer/>
</template>

<script>
import Nav from '@/components/Layout/Nav.vue';
import Banner from '@/components/Layout/Banner.vue';
import Footer from '@/components/Layout/Footer.vue';
export default {
    name: 'AcademyView',
    components:{
        Nav,
        Banner,
        Footer
    }

}
</script>

<style>

</style>