<template>
    <Nav/>
    <Banner/>
    <section class="py-3">
        <div class="container">
            <div class="row">
                    <div class="col-12">
                            <div class="lh-sm">
                                <h5 class="text-muted fs-4">
                                   ERROR 404 
                                </h5>
                                <P class="lead">Page not found</P>
                            </div>
                    </div>
            </div>
        </div>
    </section>
    <Footer/>
  </template>
  
  <script>
  import Nav from '@/components/Layout/Nav.vue';
  import Banner from '@/components/Layout/Banner.vue'
  import Footer from '@/components/Layout/Footer.vue';
  export default {
    name:'ContactView',
    components:{
      Nav,
      Banner,
      Footer
    }
  }
  </script>
  
  <style>
  
  </style>
  