<template>
  <div class="section py-5 ">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-5 col-md-5">
                <div class="w-100 bg-dark bg-football rounded-top-5 " style="height:420px">
                    <div id="carouselExampleSlidesOnly" class="carousel slide carousel-fade h-100" data-bs-ride="carousel">
                                <div class="carousel-inner h-100">
                                    <div class="carousel-item active h-100">
                                    <img src="@/assets/images/fc/fc015.jpg" alt="image here" class="img-fluid w-75 h-100">
                                        <!--Carousel Wrapper-->
                                       
                
                                       
                                    </div>
                                    <div class="carousel-item h-100">
                                    <img src="@/assets/images/fc/fc022.jpg" alt="image here" class="img-fluid w-75 h-100">
            
                                    </div>
                                    <div class="carousel-item h-100">
                                    <img src="@/assets/images/fc/fc019.jpg" alt="image here" class="img-fluid w-75 h-100">
            
                                    </div>
                                </div>
                                </div>
                       </div>
            </div>
            <div class="col-lg-7 col-md-7">
                <div class="lh-sm text-start mt-5">
                  <div class="mb-2">
                    <span class="fw-bold bg-danger p-1 text-white text-uppercase small">Introducing</span>
                   
                  </div>
                    <h3 class="sport-title fw-bold mb-lg-2  dispaly-4">
                       Edufootsport Academy?
                    </h3>
                    <p class="small fw-bold text-muted ">
                        EdufootSports is created to provide incredible childhood football experiences for boys and girls of all ages and ability levels, 
                        eliminating barriers to opportunity.
                    </p>
                                         
                    <p class="small fw-bold text-muted">
                        The Academy offers year round programmes
                         for children aged 3-12 years/14-24 years old and combines academic learning with
                          professional football training whilst developing life core skills.
                     </p>
                    <p class="small text-muted fw-bold">
                        The programme is crucial in helping young people to achieve their College Scholarship, Semi-Professional
                        and Professional Football aspirations.
                        We conduct a group training programme on weekends each term, with each term spanning 12 weeks.</p>     
                
                   
                        <div class="py-3">
                            <router-link class=" px-2 text-decoration-none text-dark float-end fw-bold" to="about">
                                <span class="small fw-bold px-2">More</span>
                                <img src="../../assets/images/icons/arrow.png" alt="icon">
                                
                            </router-link>
                        </div>
                    </div>
            </div>
        </div>
    </div>
  </div>
  <section class="bg-fe py-5">
        <div class="container">
            <div class="row justify-content-center align-items-center">
                <div class="col-12">
                    <div class="py-2 text-center lh-sm mx-auto">
                       <h3 class="sport-title fw-bold text-uppercase text-light">Our Pathway</h3> 
                    <span class="text-light fw-bold"> Finland Sports Scholarships: Advice, promotion and application processing service for talented boys and girls who are interested in applying for a full or partial scholarship to study and live their sporting dreams at a Finnish University </span>
                       
                    </div>
                </div>
            </div>
        </div>
  </section>
  <section class="py-4">
    <div class="container">
        <div class="row">
            <div class="py-5 lh-sm">
                <h3 class="sport-title fw-bold text-uppercase text-underline">
                    2023/2024 &nbsp; &nbsp; SEASON
                </h3>

            </div>
            <div class="col-lg-4 col-md-4">
                <div class="card rounded-0 mb-2">
                    <div class="card-body">
                            <h5 class="sport-title m-0">
                                Little &nbsp; Stars 
                            </h5>
                            <h6 class="fw-bold text-start text-uppercase">
                                Age: Under 3-6
                            </h6>
                            <div class="lh-sm text-start py-1">
                                <p class="m-0"><span class="fw-bold">Session: &nbsp;&nbsp;</span> 2 Sessions per Week</p>
                                <p class="m-0"><span class="fw-bold">Duration: &nbsp;</span> 12 Weeks per Term</p>
                            </div>
                            
                            <div class="card-footer bg-transparent border-0 p-0 ">
                                
                            </div>
                    </div>

                </div>
            </div>
            <div class="col-lg-4 col-md-4">
                <div class="card rounded-0 mb-2">
                    <div class="card-body">
                            <h5 class="sport-title m-0">
                                Shinning &nbsp; Stars 
                            </h5>
                            <h6 class="fw-bold text-start">
                                Age: Under 7-10
                            </h6>
                            <div class="lh-sm text-start py-1">
                                <p class="m-0"><span class="fw-bold">Session: &nbsp;&nbsp;</span> 2 Sessions per Week</p>
                                <p class="m-0"><span class="fw-bold">Duration: &nbsp;</span> 12 Weeks per Term</p>
                            </div>
                            <div class="card-footer bg-transparent border-0 p-0 ">
                                
                            </div>
                    </div>

                </div>
            </div>
            <div class="col-lg-4 col-md-4">
                <div class="card rounded-0 mb-2">
                    <div class="card-body">
                        <h5 class="sport-title m-0">
                                Real &nbsp; Stars 
                            </h5>
                            <h6 class="fw-bold text-start">
                                Age: Under 11-15 
                            </h6>
                            <div class="lh-sm text-start py-1">
                                <p class="m-0"><span class="fw-bold">Session: &nbsp;&nbsp;</span> 2 Sessions per Week</p>
                                <p class="m-0"><span class="fw-bold">Duration: &nbsp;</span> 12 Weeks per Term</p>
                            </div>
                            <div class="card-footer bg-transparent border-0 p-0 ">
                                
                            </div>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="card rounded-0 bg-danger text-light">
                    <div class="card-body">
                        <div class="card-title">
                            <h5 class="sport-title m-0">
                                Super &nbsp; Stars 
                            </h5>
                            <h6 class="fw-bold text-start"> 
                                Age: Under 16 & Above  
                            </h6>   
                            <div class="lh-sm text-start py-1">
                                <p class="m-0"><span class="fw-bold text-dark">Session: &nbsp;&nbsp;</span> 4 Sessions per Week</p>
                                <p class="m-0"><span class="fw-bold text-dark">Duration: &nbsp;</span> 12 Weeks per Term</p>
                            </div>                 
                        </div>
                    </div>
                    <div class="card-footer bg-transparent border-0 p-0 ">
                        
                    </div> 
                </div>
            </div>
        </div>
    </div>
  </section>
  <section class="bg-fe py-5">
        <div class="container">
            <div class="row justify-content-center align-items-center">
                <div class="col-12">
                    <div class="py-2 text-center lh-sm mx-auto">
                        <figure class="text-center">
                            <blockquote class="blockquote">
                                <h3 class="fw-bold text-white sport-title">Greatness &nbsp; &nbsp; Grows  &nbsp; &nbsp; Here</h3>
                            </blockquote>
                            <figcaption class="blockquote-footer text-danger fw-bold">
                                Never give up on your dreams
                            </figcaption>
                        </figure>
                    </div>
                </div>
            </div>
        </div>
  </section>

  <section class="py-5">
        <div class="container">
            <div class="row justify-content-center align-items-center">
                <div class="col-12">
                    <div class="py-2">
                        <h3 class="sport-title   fw-bold">
                            SEASON &nbsp;&nbsp;SCHEDULE&nbsp;&nbsp;2023/2024
                        </h3>
                    </div>
                    <div class="py-2 text-center lh-sm mx-auto">
                        <table class="table text-start">
                            <thead>
                                <tr>
                                <th scope="col">Sessions</th>
                                <th scope="col">Details</th>
                                </tr>
                            </thead>
                            <tbody class="text-start">
                                <tr>
                                <td>
                                   <div class="text-start">
                                    <h6 class="fw-bold">
                                        Term1
                                    </h6>
                                    <span class="fw-normal">
                                        12 weeks
                                    </span>
                                   </div>
                                </td>
                                <td>
                                    <div class="text-start">
                                        <h6 class="fw-bold">
                                            9th September - 10th December 2023
                                        </h6> 
                                    <address class="fw-normal">
                                        Refiners School, Thera Annex Estate,Lekki, Eti-Osa, Lagos
                                    </address>
                                    </div>
                                    
                                </td>
                                </tr>
                                <tr>
                                <td>
                                    <div class="text-start">
                                        <h6 class="fw-bold">
                                        Term2
                                         </h6>
                                    <span class="fw-normal">
                                        12 weeks
                                    </span>
                                    </div>
                                    
                                </td>
                                <td>
                                    <div class="text-start">
                                        <h6 class="fw-bold">
                                          6th January - 7th April 2024
                                        </h6> 
                                    <address>
                                        Refiners School, Thera Annex Estate,Lekki, Eti-Osa, Lagos
                                    </address>
                                    </div>
                                    
                                </td>
                                </tr>
                                <!--term3-->
                                <tr>
                                <td>
                                    <div class="text-start">
                                        <h6 class="fw-bold">
                                        Term3
                                         </h6>
                                    <span class="fw-normal">
                                        12 weeks
                                    </span>
                                    </div>
                                    
                                </td>
                                <td>
                                    <div class="text-start">
                                        <h6 class="fw-bold">
                                          27th April - 28th July 2024
                                        </h6> 
                                    <address>
                                        Refiners School, Thera Annex Estate,Lekki, Eti-Osa, Lagos
                                    </address>
                                    </div>
                                    
                                </td>
                                </tr>
                                <!--term3-->
                            </tbody>
                            </table>
                    </div>
                </div>
            </div>
            <div class="row py-3 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-6">
                    <div class="py-2 h-100">
                        <img src="../../assets/images/pages/stad.jpg" alt="" class="img-fluid w-100 object-fit-cover " lazy="loading" style="height:370px">
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="py-2 lh-sm">
                        <h3 class="sport-title fw-bold m-0">
                            Our &nbsp; Location
                        </h3>
                        <span class="text-start text-danger small fw-bold">Here is where you can  find us.</span>
                        <address class="py-4 lh-base">
                            <span class="fw-bold d-block">Thera Annex Estate.</span>
                            Refiners School, Thera Annex Estate,Lekki,  <br/>
                            Eti-Osa, Lagos
                            <span class="fw-bold d-block">Lagos, Nigeria</span>
                        </address>
                        <div class="align-self-bottom mt-3">
                            <button class=" btn btn-danger btn-md fw-bold rounded-0">
                                view map
                            </button>
                        </div>
                        
                    
                    </div>
                </div>
            </div>
        </div>
  </section>
</template>

<script>
export default {
    name:'Philosophy'
}
</script>

<style scoped>
.bg-football{
    background-image:url('../../assets/images/banner/stamx.png');
    background-position:center center;
    background-repeat:no repeat;
    background-blend-mode:difference;
    background-size: cover;
}

 img{
    max-width:100%;
    height:inherit;
    object-fit:cover;
    object-position:center;
}
.bg-fe{
    background-image:linear-gradient(to right,rgba(0,0,0,0.3),rgba(0,0,0,0.8)),url('../../assets/images/banner/feel.png');
    background-size:cover;
    background-repeat:no-repeat;
    background-attachment: fixed;
    background-position: center;
}
</style>