<template>
  <Nav/>
    <section class="my-5 bg-light">
        <div class="container">
            <div class="row pb-5">
                <div class="lh-sm text-center">
                    <h3 class="fw-bold sport-title mb-0">
                        OFFERS
                    </h3>
                    <span class="fw-bold small text-muted">Check out a list of our offers</span>
                </div>
          </div>
          <div class="row g-2">      
                <div class=" col-lg-3 col-md-3 ">
                    <!--fc 1v1-->
                    <div class="card " style="height:320px">
                        <img src="../assets/images/fc/fc017.jpg" class="card-img h-100 object-fit-cover" alt="...">
                        <div class="card-img-overlay">
                            <div class="position-relative">
                                <div class="position-absolute top-0 start-0">
                                    <div class="fw-bold bg-danger numb py-3 text-center h5 pe-1 text-light">01</div>
                                    <div class="text-center  h-100 overflow-hidden shadow-sm container-text " style= "text-overflow:hidden;">
                                        <h4 class="fw-bold ">Private Training</h4>
                                        <span class="small lh-sm lead">
                                            1 on 1 Residential Training session.
                                        
                                        </span> 
                                        <p class="small">Are you interested in putting in the extra work to increase your technical and  physical skills ? Book Now!</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
                <div class=" col-lg-3 col-md-3 ">
                    <!--group training-->
                    <div class="card " style="height:320px">
                        <img src="../assets/images/fc/fc018.jpg" class="card-img object-fit-cover h-100" alt="...">
                        <div class="card-img-overlay">
                            <div class="position-relative">
                                <div class="position-absolute top-0 start-0">
                                    <div class="fw-bold bg-danger numb py-3 text-center h5 pe-1 text-light">02</div>
                                    <div class="text-center  h-100 overflow-hidden shadow-sm container-text " style= "text-overflow:hidden;">
                                        <h4 class="fw-bold "> Academy Programme</h4>
                                        <span class="small lh-sm lead">
                                          	Group and Team Training session
                                        
                                        </span> 
                                        <ul class="list-style-none small text-start mt-2">
                                            <li class="list-item border border-0 bg-transparent small"> Recreational</li>
                                            <li class="list-item border border-0 bg-transparent small"> Development</li>
                                            <li class="list-item border border-0 bg-transparent small"> Advanced</li>
                                            <li class="list-item border border-0 bg-transparent small"> Elite</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
                <div class=" col-lg-3 col-md-3 ">
                    <div class="card " style="height:320px">
                        <!--friendly matches-->
                        <img src="../assets/images/fc/fc025.jpg" class="card-img h-100 object-fit-cover" alt="...">
                        <div class="card-img-overlay">
                            <div class="position-relative">
                                <div class="position-absolute top-0 start-0">
                                    <div class="fw-bold bg-danger numb py-3 text-center h5 pe-1 text-light">03</div>
                                    <div class="text-center  h-100 overflow-hidden shadow-sm container-text " style= "text-overflow:hidden;">
                                        <h4 class="fw-bold ">	Competition</h4>
                                        <span class="small lh-sm lead">
                                            Cup Games, League and Friendlies.
                                        
                                        </span> 
                                        <p class="small">Our players participate in different leagues, cup games and friendly matches every week.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
                <div class=" col-lg-3 col-md-3 ">
                    <!---season league tournaments-->
                    <div class="card " style="height:320px">
                        <img src="../assets/images/fc/fc029.jpg" class="card-img h-100 object-fit-cover" alt="...">
                        <div class="card-img-overlay">
                            <div class="position-relative">
                                <div class="position-absolute top-0 start-0">
                                    <div class="fw-bold bg-danger numb py-3 text-center h5 pe-1 text-light">04</div>
                                    <div class="text-center  h-100 overflow-hidden shadow-sm container-text " style= "text-overflow:hidden;">
                                        <h4 class="fw-bold ">Camps</h4>
                                        <span class="small lh-sm lead">
                                            	Easter and Summer camp
                                        
                                        </span> 
                                        <p class="small">Camp gives you the opportunity to train like a real EFSA player. Every aspect of the unique EFSA training method will part of the programme.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </section>
  <Footer/>
</template>

<script>
import Nav from '@/components/Layout/Nav';
import Footer from '@/components/Layout/Footer';
export default {
    name:'OfferView',
    components:{
        Nav,
        Footer
    }
}
</script>

<style>
.numb{
    height:50px;
    width:50px;
    border-radius: 50%;
}


    .container-text {
     
      width: 100%;
      height: 100%;
      background: rgba(197, 51, 51, 0.5);
      color: #fff;
      transition: opacity 0.3s ease-in-out;
    
    }

  
   
</style>