<template>
    <Nav/>
    <Banner/>
    <section class="py-3">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-md-6">
                    <div class="text-start py-5">
                        <address>
                            <h4 class="text-start fw-bold">
                                Contact Details:
                            </h4>
                           <p class="lead">- Address: Refiners School, <br/>Thera Annex Estate, Ibeju Lekki, Lagos</p>  
                           <tel class="lead">- Phone: 08167912038</tel>
                           <p class="lead"> - Email: info@edufootsports.com </p>

                        </address>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="py-5">
                        <form>
                            <div class="mb-1">
                                <label for="exampleInputEmail1" class="form-label fw-bold">Email address</label>
                                <input type="email" class="form-control rounded-0 border border-2" id="exampleInputEmail1" aria-describedby="emailHelp">
                            </div>
                            <div class="mb-1">
                                <label for="exampleInputPassword1" class="form-label fw-bold">Subject</label>
                                <input type="text" class="form-control rounded-0 border border-2" id="exampleInputPassword1">
                            </div>
                            <div class="mb-1">
                                <label for="exampleFormControlTextarea1" class="form-label fw-bold">Message</label>
                                <textarea class="form-control rounded-0" id="exampleFormControlTextarea1 rounded-0" rows="3"></textarea>
                             </div>
                            <div class="mb-3 form-check">
                                <input type="checkbox" class="form-check-input rounded-0 border border-2" id="exampleCheck1">
                                <label class="form-check-label " for="exampleCheck1">Check me out</label>
                            </div>
                            <button type="submit" class="btn btn-dark rounded-0 w-100">Submit Form</button>
                        </form>
                    </div>
                </div>
            </div>
            <div class="row py-3">
                <div class="col-12">
         <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m11m3!1d971.6776606807332!2d-12.04332762985656!3d41.902783079"></iframe>

                </div>
            </div>
        </div>
    </section>
    <Footer/>
  </template>
  
  <script>
  import Nav from '@/components/Layout/Nav.vue';
  import Banner from '@/components/Layout/Banner.vue'
  import Footer from '@/components/Layout/Footer.vue';
  export default {
    name:'ContactView',
    components:{
      Nav,
      Banner,
      Footer
    }
  }
  </script>
  
  <style>
  
  </style>
  