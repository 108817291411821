import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import EnrollView from '../views/EnrollView.vue'
import ContactView from '../views/ContactView.vue'
import OfferView from '@/views/OfferView.vue'
import NewsView from '@/views/NewsView.vue'
import AcademyView from '@/views/AcademyView.vue'
import notFoundView from '@/views/notFoundView.vue'
import CampView from '@/views/CampView.vue'
import SchoolView from '@/views/SchoolView.vue'
const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/news',
    name: 'news',
    component: NewsView
  },

  {
    path: '/academy',
    name: 'academy',
    component: AcademyView
  },
  {
    path: '/offer',
    name: 'offer',
    component: OfferView
  },
    {
      path: '/enroll',
      name: 'enroll',
      component: EnrollView
    },
    {
      path: '/contact',
      name: 'contact',
      component: ContactView
    },
    {
      path: '/camp',
      name: 'camp',
      component: CampView
    },
    {
      path: '/school',
      name: 'Training + Education',
      component: SchoolView
    },

  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  //redirect
  {
    path:'/contacts',
    redirect:'contact'
  },
  //catchall 404
  {
    path:'/:catchAll(.*)',
    name:'notfound',
    component: notFoundView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
