<template>
    <Nav/>
    <Banner/>
    <section class="py-3">
        <div class="container">
            <div class="row">
              <div class="col-12">
                
                <div class="elfsight-app-29ab4dc2-0b24-4857-b6f1-203b55cd02b7" data-elfsight-app-lazy></div>
              </div>
            </div>
        </div>
    </section>
    <section class="py-3">
        <div class="container">
            <div class="lh-sm">
                <h3 class="fw-bold">
                   Inside Training and Match Highlights
                </h3>
            </div>
            <div class="row">
              <div class="col-lg-4 col-md-6 col-sm-12">
                <div class="card">
                    <iframe class="w-100 h-100" src="https://www.youtube.com/embed/qPOhfY2VDN4?si=PxDPoylkwznRGk_N" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12">
                <div class="card">
                    <iframe class="w-100 h-100" src="https://www.youtube.com/embed/r87FJ8WquYw?si=kKG6_J7AxvDVRXd8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12">
                <div class="card">
                    <iframe class="w-100 h-100" src="https://www.youtube.com/embed/Xcd_RniAybE?si=HKw8-meiG0xKi6hH" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
            </div>
            </div>
        </div>
    </section>
  
    <Footer/>
  
</template>

<script>
import Nav from '@/components/Layout/Nav.vue';
import Banner from '@/components/Layout/Banner.vue';
import Footer from '@/components/Layout/Footer.vue';
export default {
name:'NewsView',
components:{
    Nav,
    Banner,
    Footer
},

}
</script>

<style>
.text-jus{
    text-align: justify;
    line-height: .2;
}
</style>