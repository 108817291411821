<template>
  <nav class="navbar fw-bold bg-football border-0 m-0 navbar-expand-lg  p-lg-4">
  <div class="container">
    <div class="" style="height:50px;width:50px">
      <router-link class="navbar-brand text-light" to="/">
      <img src="../../assets/logo/logo.png" alt="" srcset="" lazy="loading " class="img-fluid h-100">
    </router-link>
    </div>
    
    <button class="navbar-toggler border-danger" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon border-danger"></span>
    </button>
    <div class="collapse navbar-collapse " id="navbarNavDropdown">
      <ul class="navbar-nav mx-auto">
        <li class="nav-item pe-lg-3 ">
          <router-link class="nav-link active text-light" aria-current="page" to="/">Home</router-link>
        </li>
       
        <li class="nav-item pe-lg-3">
          <router-link class="nav-link text-light" to="news">News</router-link>
        </li>
        <li class="nav-item pe-lg-3">
          <router-link class="nav-link text-light" to="offer">Academy</router-link>
        </li>
        <li class="nav-item pe-lg-3">
          <router-link class="nav-link text-light" to="camp">Camp</router-link>
        </li>
        <li class="nav-item pe-lg-3">
          <router-link class="nav-link text-light" to="school">Football + School</router-link>
        </li>
        <li class="nav-item pe-lg-3">
          <router-link class="nav-link text-light" to="about">About</router-link>
        </li>
        <li class="nav-item pe-lg-3">
          <router-link class="nav-link text-light" to="contact">Contact</router-link>
        </li>
      </ul>
      <div class="ms-auto">
        <router-link class="btn btn-danger btn-md rounded-0 px-3 small" to="enroll">Sign up</router-link>
      </div>
    </div>
  </div>
</nav>
</template>

<script>
import router from '@/router';

export default {
    name: 'Nav',
    components: { router }
}
</script>

<style >
nav{
  background-color:#1F2742;
}
.btn-football{
  background: #BB2533;
  color:#fff;     
}
</style>