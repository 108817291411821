<template>
    <Nav/>
    <Banner/>
      <div class="section py-5 ">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-7 col-md-7">
                <div class="lh-sm text-start mt-5 w-75">
                    <h5 class="sport-title fw-bold mb-lg-2  dispaly-4">
                       COMING SOON
                    </h5>
                    <hr/>
                    <h1 class=" fw-bold">
                        GET NOTIFIED ABOUT EDUFOOTSPORTS ACADEMY CAMP
                    </h1>
                    <form class="row g-1">
                        <div class="col-md-8">
                            <label for="inputEmail4" class="form-label">Email</label>
                            <input type="email" class="form-control rounded-0" id="inputEmail4">
                        </div>
                        <div class="col-md-8">
                            <label for="tel" class="form-label">Phone Number</label>
                            <input type="tel" class="form-control rounded-0" id="tel">
                        </div>
                        <div class="col-12">
                            <button type="submit" class="btn btn-dark rounded-0 col-md-8 my-1 ">Submit</button>
                        </div>
                    </form>
                    </div>
            </div>
            <div class="col-lg-5 col-md-5">
                <div class="w-100 bg-dark bg-football rounded-top-5 " style="height:420px">
                    <div id="carouselExampleSlidesOnly" class="carousel slide carousel-fade h-100" data-bs-ride="carousel">
                                <div class="carousel-inner h-100">
                                    <div class="carousel-item active h-100">
                                    <img src="@/assets/images/fc/people.jpeg" alt="image here" class="img-fluid w-75 h-100">
                                    </div>
                                </div>
                                </div>
                       </div>
            </div>
        </div>
    </div>
  </div>
      
    <Footer/>
  </template>
  
  <script>
  import Nav from '@/components/Layout/Nav.vue';
  import Banner from '@/components/Layout/Banner.vue'
  import Footer from '@/components/Layout/Footer.vue';
  export default {
    name:'CampView',
    components:{
      Nav,
      Banner,
      Footer
    }
  }
  </script>
  
  <style>
  
  </style>
  