<template>
  <Nav/>
  <Banner/>
  <Philosophy/>
  <section class="py-3 bg-danger text-light">
    <div class="container">
        <div class="row">
            <div class="col-12">
              <div class="text-center">
                       <h3 class="sport-title fw-bold text-light">EASTER CAMP 2024</h3>
                        <span class="text-danger text-uppercase fw-bold text-light">all you need to know</span>
                        <div class="py-2">
                            <button class="btn btn-light btn-md sport-title px-5 rounded-0 mx-auto shadow-sm fw-bold border-0">Coming Soon</button>
                        </div>
              </div>
            
            </div>
        </div>
    </div>
  </section>

  <Footer/>
</template>

<script>
// @ is an alias to /src
import Nav from '@/components/Layout/Nav'
import Banner from '@/components/Home/Banner.vue'
import Philosophy from '@/components/Home/Philosophy.vue'
import News from '@/components/Home/News.vue'
import Footer from '@/components/Layout/Footer.vue'

export default {
  name: 'HomeView',
  components: {
    Nav,
    Banner,
    Philosophy,
    News,
    Footer
  }
}
</script>
