<template>
  <!--footer-->
  <section class="bg-dark text-light">
        <div class="container">
  <div class="py-5 ">
    <div class="row">
      <div class=" col-lg-4 col-md-4 col-sm-6 col-xs-6 col-6">
        <div class="my-2" style="width:100px; height:100px">
            <router-link class="navbar-brand text-light" to="/">
            <img src="../../assets/logo/logo.png" alt="website logo" srcset="" lazy="loading " class="img-fluid h-100">
          </router-link>
        </div>
      </div>
      <div class=" col-lg-4 col-md-4 col-sm-6 col-xs-6 col-6">
        <h5 class="fw-bold">Links</h5>
        <ul class="nav flex-column ">
          <li class="nav-item mb-2 text-light"><a href="#" class="nav-link text-light p-0">Home</a></li>
          <li class="nav-item mb-2 text-light"><a href="#" class="nav-link text-light p-0">Features</a></li>
          <li class="nav-item mb-2 text-light"><a href="#" class="nav-link text-light p-0">Pricing</a></li>
          <li class="nav-item mb-2 text-light"><a href="#" class="nav-link text-light p-0">FAQs</a></li>
          <li class="nav-item mb-2 text-light"><a href="#" class="nav-link text-light p-0">About</a></li>
        </ul>
      </div>

      <div class=" col-lg-4 col-md-4 col-sm-12">
        <form action="" class="row row-cols-lg-auto g-0 align-items-center">
            <div class="col-12 col-sm-6 col-xs-6">
                <label for="inputPassword5" class="form-label fw-bold text-white sport-title h3">Subscribe to our newsletter</label>
                <input type="password" id="inputPassword5" class="form-control rounded-0" aria-describedby="passwordHelpBlock">
                <div id="passwordHelpBlock" class="form-text text-light">
                    Monthly digest of whats new and exciting from us.
                </div>
            </div>
            <div class="col-12 col-sm-6 col-xs-6 mt-3">
                <button type="submit" class="btn btn-danger btn-md rounded-0 ">Submit</button>
            </div>
        </form>
        </div>

    </div>

    <div class="d-flex justify-content-between py-4 my-2 border-top ">
      <p class="fw-bold mt-5 pt-2">&copy; 2023 Company, Inc. All rights reserved.</p>
      <div class="lh-sm mb-0">
        
        <ul class="m-0 d-flex justify-content-between align-items-center">
        <li class="overflow-hidden mb-0" style="height:75px;width:75px;border-radius:50%; background-color: aliceblue;">
            <img src="../../assets/images/icons/af1.png" alt="" class="img-circle" style="height:100%;width:100%">
        </li>
        <li class="mb-0">
            <img src="../../assets/images/icons/af2.png" alt="" class="img-circle" style="height:150px;width:150px">
        </li>
     
      </ul>
      </div>
     
    </div>
  </div>
</div>

  <!---go-->
  </section>
</template>

<script>
export default {
    name:'Footer'
}
</script>

<style>

</style>