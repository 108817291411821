<template>
  <section class="bg-dark py-5 rounded-top-right">
    <div class="container">
        <div class="row justify-content-between align-items-between">
            <div class="d-flex justify-content-between align-items-center">
                <div class="py-3 lh-sm m-0 p-0">
                    <h3 class="sport-title fw-bold text-light">
                        News
                    </h3>
                    <span class="fw-bold small text-danger">Get involve with the latest news.</span>
                </div>
                <div class="float-end text-light">
                    <router-link class=" px-2 text-decoration-none text-dark float-end fw-bold" to="news">
                                <span class="small fw-bold px-2 h1 text-white">More</span>
                                <img src="../../assets/images/icons/arrow.png" alt="icon">
                                
                    </router-link>
                </div>
            </div>
            
            </div>
            <div class="row">
            <div class="col-lg-4 col-md-4">
                <div class="card rounded-0 mb-2 ">
                    <img src="../../assets/images/fc/fc005.jpg" class="card-img-top object-fit-cover object-position-center  " alt="images" style="max-height:250px">
                    <div class="card-body">
                        <div class="card-title fw-bold mb-0">2023/2024 Season Begins</div>
                        <span class="small bg-danger-subtle rounded-pill px-2 text-muted">4mins ago</span>
                        <div class="py-2" style="height:70px">    
                            <p class="small lh-sm">
                                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Doloribus sed aliquid at minima id? Ipsa et,
                                aspernatur ratione quos explicabo nobis harum beatae quae provident mo...
                            </p>
                        </div>
                        <div class="mt-4 float-end">
                            <a class=" btn btn-md btn-link text-danger fw-bold small">Read More</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-4">
                <div class="card rounded-0 mb-2 ">
                    <img src="../../assets/images/fc/20221028_161104.jpg" class="card-img-top object-fit-cover object-position-center  " alt="images" style="max-height:250px">
                    <div class="card-body">
                        <div class="card-title fw-bold mb-0">2023/2024 Season Begins</div>
                        <span class="small bg-danger-subtle rounded-pill px-2 text-muted">4mins ago</span>
                        <div class="py-2" style="height:70px">    
                            <p class="small lh-sm">
                                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Doloribus sed aliquid at minima id? Ipsa et,
                                aspernatur ratione quos explicabo nobis harum beatae quae provident mo...
                            </p>
                        </div>
                        <div class="mt-4 float-end">
                            <a class=" btn btn-md btn-link text-danger fw-bold small">Read More</a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-4">
                <div class="card rounded-0 mb-2 ">
                    <img src="../../assets/images/fc/20221112_104836.jpg" class="card-img-top object-fit-cover object-position-center  " alt="images" style="max-height:250px">
                    <div class="card-body">
                        <div class="card-title fw-bold mb-0">2023/2024 Season Begins</div>
                        <span class="small bg-danger-subtle rounded-pill px-2 text-muted">4mins ago</span>
                        <div class="py-2" style="height:70px">    
                            <p class="small lh-sm">
                                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Doloribus sed aliquid at minima id? Ipsa et,
                                aspernatur ratione quos explicabo nobis harum beatae quae provident mo...
                            </p>
                        </div>
                        <div class="mt-4 float-end">
                            <a class=" btn btn-md btn-link text-danger fw-bold small">Read More</a>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
  </section>
</template>

<script>
export default {
    name:'News'
}
</script>

<style scoped>
  .rounded-left  {
    border-top-left-radius: 10px; /* Adjust the value to control the rounding */
  }
</style>